.owl-carousel .owl-item {
  transition: all 0.3s ease-in-out;
}

.owl-carousel .owl-item .card {
  padding: 30px;
  position: relative;
}

.owl-carousel .owl-stage-outer {
  overflow-y: auto !important;
  padding-bottom: 40px;
}

.owl-carousel .owl-item img {
  height: 100%;
  /* object-fit: cover; */
  border-radius: 10px;
}

.owl-carousel .owl-item .card .name {
  position: absolute;
  bottom: -20px;
  left: 33%;
  color: #101c81;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: aquamarine;
  padding: 0.3rem 0.4rem;
  border-radius: 5px;
  box-shadow: 2px 3px 15px #3c405a;
}

.owl-carousel .owl-item .card {
  opacity: 0.2;
  transform: scale3d(0.8, 0.8, 0.8);
  transition: all 0.3s ease-in-out;
  background-color: #2c3447;
}

.owl-carousel .owl-item.active.center .card {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  background-color: #2c3447;
}

.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.owl-theme .owl-dots .owl-dot span {
  height: 10px;
  background: #6b7a86 !important;
  border-radius: 32px !important;
  opacity: 0.8;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  height: 13px;
  width: 13px;
  opacity: 1;
  transform: translateY(2px);
  background: #e8eef4f7 !important;
}

@media (min-width: 480.6px) and (max-width: 575.5px) {
  .owl-carousel .owl-item .card .name {
    left: 24%;
  }
}

@media (max-width: 360px) {
  .owl-carousel .owl-item .card .name {
    left: 30%;
  }
}
.testimonialheading {
  background: black;
  color: white;
  max-width: 100%;
}
.testimonial {
  color: white;
  text-align: center;
  font-family: Poppins;
}
.testimonilaname {
  font-family: Montserrat;
  white-space: nowrap;
  color: white;
}

/* start css */
#full-stars-example {
  text-align: center;
}
#full-stars-example .rating-group {
  display: inline-flex;
}
#full-stars-example .rating__icon {
  pointer-events: none;
}
#full-stars-example .rating__input {
  position: absolute !important;
  left: -9999px !important;
}
#full-stars-example .rating__label {
  cursor: pointer;
  padding: 0 0.2em;
  /* font-size: 2rem; */
  font-size: 20px;
}
#full-stars-example .rating__icon--star {
  color: orange;
}
#full-stars-example .rating__icon--none {
  color: #eee;
}
#full-stars-example
  .rating__input--none:checked
  + .rating__label
  .rating__icon--none {
  color: red;
}
#full-stars-example
  .rating__input:checked
  ~ .rating__label
  .rating__icon--star {
  color: #ddd;
}
#full-stars-example .rating-group:hover .rating__label .rating__icon--star {
  color: orange;
}
#full-stars-example .rating__input:hover ~ .rating__label .rating__icon--star {
  color: #ddd;
}
#full-stars-example
  .rating-group:hover
  .rating__input--none:not(:hover)
  + .rating__label
  .rating__icon--none {
  color: #eee;
}
#full-stars-example
  .rating__input--none:hover
  + .rating__label
  .rating__icon--none {
  color: red;
}

#half-stars-example .rating-group {
  display: inline-flex;
}
#half-stars-example .rating__icon {
  pointer-events: none;
}
#half-stars-example .rating__input {
  position: absolute !important;
  left: -9999px !important;
}
#half-stars-example .rating__label {
  cursor: pointer;
  /* if you change the left/right padding, update the margin-right property of .rating__label--half as well. */
  padding: 0 0.1em;
  font-size: 2rem;
}
#half-stars-example .rating__label--half {
  padding-right: 0;
  margin-right: -0.6em;
  z-index: 2;
}
#half-stars-example .rating__icon--star {
  color: orange;
}
#half-stars-example .rating__icon--none {
  color: #eee;
}
#half-stars-example
  .rating__input--none:checked
  + .rating__label
  .rating__icon--none {
  color: red;
}
#half-stars-example
  .rating__input:checked
  ~ .rating__label
  .rating__icon--star {
  color: #ddd;
}
#half-stars-example .rating-group:hover .rating__label .rating__icon--star,
#half-stars-example
  .rating-group:hover
  .rating__label--half
  .rating__icon--star {
  color: orange;
}
#half-stars-example .rating__input:hover ~ .rating__label .rating__icon--star,
#half-stars-example
  .rating__input:hover
  ~ .rating__label--half
  .rating__icon--star {
  color: #ddd;
}
#half-stars-example
  .rating-group:hover
  .rating__input--none:not(:hover)
  + .rating__label
  .rating__icon--none {
  color: #eee;
}
#half-stars-example
  .rating__input--none:hover
  + .rating__label
  .rating__icon--none {
  color: red;
}

#full-stars-example-two .rating-group {
  display: inline-flex;
}
#full-stars-example-two .rating__icon {
  pointer-events: none;
}
#full-stars-example-two .rating__input {
  position: absolute !important;
  left: -9999px !important;
}
#full-stars-example-two .rating__input--none {
  display: none;
}
#full-stars-example-two .rating__label {
  cursor: pointer;
  padding: 0 0.1em;
  font-size: 2rem;
}
#full-stars-example-two .rating__icon--star {
  color: orange;
}
#full-stars-example-two
  .rating__input:checked
  ~ .rating__label
  .rating__icon--star {
  color: #ddd;
}
#full-stars-example-two .rating-group:hover .rating__label .rating__icon--star {
  color: orange;
}
#full-stars-example-two
  .rating__input:hover
  ~ .rating__label
  .rating__icon--star {
  color: #ddd;
}
.img-card {
  height: 100px;
  width: 100px;
  margin: auto;
}
.videocard {
  background-color: transparent !important;
  height: 654px;
}
.top-border {
  margin-top: 1vw;
  border-top: 1px #f27221 solid;
  width: 100vw;
}
