@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
.material {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  transform: rotateZ(-20deg);
}

@keyframes rotate {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

.dna {
  /*line*/
  position: relative;
  width: 1px;
  height: 100px;
  border: 1px dotted #e7e7e7;
  box-shadow: 0 0 15px #e7e7e7;
  background: transparent;
  margin: 0px 10px;
  animation: rotate 3s linear infinite;
}
.dna::before {
  /*top dot*/
  content: "";
  position: absolute;
  top: -2px;
  left: -5px;
  width: 10px;
  height: 10px;
  background: #1cffb3;
  border-radius: 50%;
  box-shadow: 0 0 15px #1cffb3;
}
.dna::after {
  /*bottom dot*/
  content: "";
  position: absolute;
  bottom: -2px;
  left: -5px;
  width: 10px;
  height: 10px;
  background: #0ff;
  border-radius: 50%;
  box-shadow: 0 0 12px #0ff;
}

.dna:nth-child(1) {
  animation-delay: -0.15s;
}
.dna:nth-child(2) {
  animation-delay: -0.3s;
}
.dna:nth-child(3) {
  animation-delay: -0.45s;
}
.dna:nth-child(4) {
  animation-delay: -0.6s;
}
.dna:nth-child(5) {
  animation-delay: -0.75s;
}
.dna:nth-child(6) {
  animation-delay: -0.9s;
}
.dna:nth-child(7) {
  animation-delay: -1.05s;
}
.dna:nth-child(8) {
  animation-delay: -1.2s;
}
.dna:nth-child(9) {
  animation-delay: -1.35s;
}
.dna:nth-child(10) {
  animation-delay: -1.5s;
}
.dna:nth-child(11) {
  animation-delay: -1.65s;
}
.dna:nth-child(12) {
  animation-delay: -1.8s;
}
.dna:nth-child(13) {
  animation-delay: -1.95s;
}
.dna:nth-child(14) {
  animation-delay: -2.1s;
}
.dna:nth-child(15) {
  animation-delay: -2.25s;
}
.dna:nth-child(16) {
  animation-delay: -2.3s;
}
.sportxdna {
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bolder;
  font-size: 16dvh;
  font-family: "Suissnord";
  white-space: nowrap;
}
.sportxdna2 {
  position: absolute;
  top: 62%;
  left: 52%;
  transform: translate(-50%, -50%);
  color: #f27221;
  font-weight: bolder;
  font-family: "Suissnord";
  font-size: 120px;
  max-width: 100%;
}
.dnasection2heading {
  color: white;
  font-size: 55px;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
}
.dnaheadingdetail {
  font-size: 25px;
}
.dnasection2heading2 {
  color: white;
  font-size: 40px;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
}
.footergetstart {
  background-color: #f27221;
}
.contentfooter {
  margin: 20px;
  font-size: 36px;
  color: white;
}
.getstartbtn {
  background: #f27221;
  border: 2px solid white;
  padding: 10px 30px 12px 30px;
  color: #fff;
  font-weight: bold;
  transition: 0.4s;
  border-radius: 50px;
  width: 70%;
  font-size: 36px;
  margin: 4%;
}
.bodrationsection {
  width: 100%;
  height: 100%;
  position: unset;
  opacity: 0.4;
}

.iconsizedna {
  color: white;
  font-size: 160px;
  padding: 20px;
}
.iconrowdna
{
 text-align: center;
}
.dnaicondetail
{
  /* margin: 30px 113px; */
  color: #f27221;
  margin: 20px;

}


/* Mobile devices: */
@media only screen and (max-width: 480px) {


  .bgimgcontent {
    transform: rotate(-90deg);
    margin-top: 177%;
    height: 30% !important;
    width: 100% !important;
  }

  .dnasection2heading {
    color: white;
    font-size: 28px;
    font-family: Montserrat;
    font-style: italic;
    font-weight: 500;
  }
  .dnaheadingdetail {
    font-size: 18px;
  }
  .icocontent {
    font-size: 10px !important;
    text-align: center;
  }

  .iconsizedna {
    color: white;
    font-size: 50px;
    padding: 20px;
  }
  .dnaicondetail
  {
    color: #f27221;
  
  }
}



/* Create three equal columns that floats next to each other */
.column {
  float: left;
  width: 33.33%;
  padding: 10px;
}


@media (min-width: 360px) {
  .sportxdna {
    position: absolute;
    top: 53%;
    left: 52%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-size: 3dvh;
    font-family: "Suissnord";
    white-space: nowrap;
  }
  .sportxdna2 {
    position: absolute;
    top: 55%;
    left: 52%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-family: "Suissnord";
    font-size: 3dvh;
    max-width: 100%;
  }

}
@media (min-width: 480px) {
  .sportxdna {
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-size: 3dvh;
    font-family: "Suissnord";
    white-space: nowrap;
  }
  .sportxdna2 {
    position: absolute;
    top: 55%;
    left: 53%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-family: "Suissnord";
    font-size: 3dvh;
    max-width: 100%;
  }

}
@media (min-width: 570px) {
  .sportxdna {
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-size: 3dvh;
    font-family: "Suissnord";
    white-space: nowrap;
  }
  .sportxdna2 {
    position: absolute;
    top: 55%;
    left: 53%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-family: "Suissnord";
    font-size: 3dvh;
    max-width: 100%;
  }

}
@media (min-width: 920px) {

  .sportxdna {
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-size: 10dvh;
    font-family: "Suissnord";
    white-space: nowrap;
  }
  .sportxdna2 {
    position: absolute;
    top: 58%;
    left: 52%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-family: "Suissnord";
    font-size: 10dvh;
    max-width: 100%;
  }

}