
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");

.full-screen-container {
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  /* background-image: url(); */
  
}

.login-container {
  padding: 50px 30px;
  min-width: 400px;
  width: 50%;
  max-width: 600px;
}

.login-title {
  color: #fff;
  text-align: center;
  margin: 0;
  margin-bottom: 40px;
  font-size: 2.5em;
  font-weight: normal;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-group label {
  color: #fff;
  font-weight: lighter;
  font-size: 1.5em;
  margin-bottom: 7px;
}

.input-group input {
  font-size: 1.5em;
  padding: 0.1em 0.25em;
  background-color: #FFFFFF;
  border: 1px solid hsl(201, 100%, 6%);
  outline: none;
  border-radius: 5px;
  color: black;
  /* font-weight: lighter; */
  opacity: 0.6 !important;
  
}

.input-group input:focus {
  border: 1px solid #F27221;
}

.login-button {
  padding: 10px 30px;
  width: 100%;
  border-radius: 5px;
  background: hsla(201, 100%, 50%, 0.1);
  border: 1px solid #F27221;
  outline: none;
  font-size: 1.5em;
  color: #fff;
  font-weight: lighter;
  margin-top: 20px;
  cursor: pointer;
}

/* .login-button:hover {
  background-color: #FF450A;
} */

.login-button:focus {
  background-color: #F27221;
}
.backgournimg
{
  background-size: cover;
  position: absolute;
  height: auto;
  width: 100%;
}
.centered
{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ff450a;
    /* font-size: 224px; */
    font-weight: bolder;
    opacity: 0.3;
    font-family: Montserrat;
}
/* .enteroptform
{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ff450a;
    opacity: 1;
} */

.enteroptform
{
    position: absolute;
    /* top: 55%;
    left: 50%; */
    transform: translate(-50%, -50%);
    color: #ff450a;
    opacity: 1;
    /* width: inherit; */
    display: contents;
  
}

/* loader start */
  @keyframes audio-wave {
    0%{
      height: 10px;
      trnsform: translateY(0px);
      background: #ff450a;
    }
    25%{
      height: 40px;
      trnsform: translateY(-5px) scaleY(1.7);
      background:#ff450a;
    }
    50%{
      height: 10px;
      trnsform: translateY(0px) scaleY(1.7);
      background:#ff450a;
    }
    100%{
      height: 10px;
      trnsform: translateY(0px) scaleY(1.7);
      
      background: #ff450a;
    }
  }
  
  .sound-wave{
    display:flex;
    justify-content: center;
    align-items:center;
    gap:8px;
    height:60px
  }
  .sound-wave span{
    height:18px;
    width:10px;
    display:block;
    border-radius:8px;
    background:#ff450a;
    animation:audio-wave 2.2s infinite ease-in-out
    
  }
  .sound-wave span:nth-child(2) {
    left:11px;
    background:red;
    animation-delay:0.2s
  }
  .sound-wave span:nth-child(3){
    left:22px;
    animation-delay:0.4s
  }
  .sound-wave span:nth-child(4){
    left:33px;
    animation-delay:0.6s
  }
  .sound-wave span:nth-child(5){
    left:44px;
    animation-delay:0.8s
  }
  .sound-wave span:nth-child(6){
    left:55px;
    animation-delay:1s
  }
/* loader end */
.changenumber
{
  cursor: pointer;
  float: inline-end;
}
.submtibtn
{
  margin: 5px;
}
