@font-face {
  font-family: "Spot Light";
  src: url("../../public/assets/font/Spot Light.otf");
}


.inner-page {
  background: black;
}

.imgdiv {
  /* padding: 6px; */
  width: 95%;
  height: 90%;
  cursor: pointer;
  border-radius: 38px;
}

.imgdiv:hover {
  box-shadow: 0px 0px 12px 0px #f27221;
}

.pagename {
  width: 570px;
  height: 140px;
  top: 400px;
  left: -100px;
  font-weight: 600;
  font-size: 130px;
  line-height: 140px;
  opacity: 50%;
  position: absolute;
  transform: rotate(-90deg);
  color: #ffffff;
}

.mulitipleimgpagename {
  width: 570px;
  height: 140px;
  top: 420px;
  left: -52px;
  font-weight: 600;
  font-size: 120px;
  line-height: 140px;
  opacity: 50%;
  position: absolute;
  transform: rotate(-90deg);
  color: #ffffff;
}

.Gallerybackbtn1 {
  color: #f27221;
  font-weight: bolder;
  font-family: "Spot Light";
}

.Gallerybackbtn2 {
  color: white;
  font-weight: bolder;
  font-family: "Spot Light";
}

.gallerybackbtn {
  color: #f27221;
  font-size: 28px;
  margin: 32px;
}

.h1heading {
  margin-top: 29px;
  margin-bottom: 22px;
}

/* .imageuppertext1
{
  position: absolute;
  top: 50%;
  left: 46%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bolder;
  font-size: 20px;
  font-family: "Suissnord";
} */

.functionaltraining1 {
  position: absolute;
  top: 45%;
  left: 80%;
  transform: translate(-50%, -50%);
  color: #f27221;
  font-weight: bolder;
  font-size: 50px;
  font-family: "Spot Light";
}

.functionaltraining2 {
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bolder;
  font-family: "Spot Light";
  font-size: 50px;
  max-width: 100%;
}

.strenght1 {
  position: absolute;
  top: 45%;
  left: 37%;
  transform: translate(-50%, -50%);
  color: #f27221;
  font-weight: bolder;
  font-size: 50px;
  font-family: "Spot Light";
}

.strenght2 {
  position: absolute;
  top: 50%;
  left: 37%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bolder;
  font-family: "Spot Light";
  font-size: 50px;
  max-width: 100%;
}

.runningarea1 {
  position: absolute;
  top: 115%;
  left: 38%;
  transform: translate(-50%, -50%);
  color: #f27221;
  font-weight: bolder;
  font-size: 50px;
  font-family: "Spot Light";
}

.runningarea2 {
  position: absolute;
  top: 120%;
  left: 38%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bolder;
  font-family: "Spot Light";
  font-size: 50px;
  max-width: 100%;
}

.personaltraining1 {
  position: absolute;
  top: 115%;
  left: 80%;
  transform: translate(-50%, -50%);
  color: #f27221;
  font-weight: bolder;
  font-size: 50px;
  font-family: "Spot Light";
}

.personaltraining2 {
  position: absolute;
  top: 120%;
  left: 80%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bolder;
  font-family: "Spot Light";
  font-size: 50px;
  max-width: 100%;
}

.inner-page {
  padding-top: 100px;
}

.multipleimgsection {
  height: 80%;
  width: 80%;
  margin-top: 5px;
  background-image: url("../images/Galleryimgas/bgimg.png");
  border-radius: 25px;
  box-shadow: rgba(255, 253, 253, 0.35) 0px 5px 15px;
  /* padding: auto; */
  background-repeat: no-repeat;
}

.imgdivmulitiple {
  /* padding: 6px;
    width: 60%;
    height: 80%; */
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 5px;
}

@media only screen and (min-width: 360px) and (max-width: 418px) {
  .functionaltraining1 {
    top: 25%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-size: 35px;
    font-family: "Spot Light";
  }

  .functionaltraining2 {
    top: 28%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-family: "Spot Light";
    font-size: 35px;
    max-width: 100%;
  }

  .strenght1 {
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-size: 35px;
    font-family: "Spot Light";
  }

  .strenght2 {
    top: 53%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-family: "Spot Light";
    font-size: 35px;
    max-width: 100%;
  }


  .runningarea1 {
    top: 85%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-size: 35px;
    font-family: "Spot Light";
  }

  .runningarea2 {
    top: 88%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-family: "Spot Light";
    font-size: 35px;
    max-width: 100%;
  }

  .personaltraining1 {
    top: 112%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-size: 35px;
    font-family: "Spot Light";
  }

  .personaltraining2 {
    top: 115%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-family: "Spot Light";
    font-size: 35px;
    max-width: 100%;
  }

}

@media only screen and (min-width:418px) and (max-width:479px) {
  .functionaltraining1 {
    top: 25%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-size: 35px;
    font-family: "Spot Light";
  }

  .functionaltraining2 {
    top: 28%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-family: "Spot Light";
    font-size: 35px;
    max-width: 100%;
  }

  .strenght1 {
    top: 60%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-size: 35px;
    font-family: "Spot Light";
  }

  .strenght2 {
    top: 63%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-family: "Spot Light";
    font-size: 35px;
    max-width: 100%;
  }


  .runningarea1 {
    top: 95%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-size: 35px;
    font-family: "Spot Light";
  }

  .runningarea2 {
    top: 98%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-family: "Spot Light";
    font-size: 35px;
    max-width: 100%;
  }

  .personaltraining1 {
    top: 128%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-size: 35px;
    font-family: "Spot Light";
  }

  .personaltraining2 {
    top: 130%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-family: "Spot Light";
    font-size: 35px;
    max-width: 100%;
  }


}

@media only screen and (min-width: 480px) and (max-width: 570px) {
  
  .functionaltraining1 {
    top: 25%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-size: 35px;
    font-family: "Spot Light";
  }

  .functionaltraining2 {
    top: 28%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-family: "Spot Light";
    font-size: 35px;
    max-width: 100%;
  }

  .strenght1 {
    top: 60%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-size: 35px;
    font-family: "Spot Light";
  }

  .strenght2 {
    top: 63%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-family: "Spot Light";
    font-size: 35px;
    max-width: 100%;
  }


  .runningarea1 {
    top: 95%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-size: 35px;
    font-family: "Spot Light";
  }

  .runningarea2 {
    top: 98%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-family: "Spot Light";
    font-size: 35px;
    max-width: 100%;
  }

  .personaltraining1 {
    top: 128%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-size: 35px;
    font-family: "Spot Light";
  }

  .personaltraining2 {
    top: 130%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-family: "Spot Light";
    font-size: 35px;
    max-width: 100%;
  }

}

@media only screen and (min-width: 571px) and (max-width: 768px) {}



/* @media only screen and (min-width: 360px) and (max-width: 479px)
@media only screen and (min-width: 480px) and (max-width: 480px)
@media only screen and (min-width: 481px) and (max-width: 768px) */
/* High-resolution screens: */
/* @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) { ... } */

/*  */