@import url("https://fonts.googleapis.com/css2?family=Vampiro+One&display=swap");

.aboutheaderimg {
  width: 100%;
  height: 100%;
  /* box-shadow: 0 0 15px 5px #f27221; */
  box-shadow: #f27221 18px 0px 0px 0px, #f27221 10px -8px 0px 8px;
}
.founderssection {
  margin-top: 50px;
  background-image: url("../images/aboutbackgourndimg.png");
  /* background-repeat: no-repeat; */
  background-size: 100%;
}
.codunderh1 {
  color: white;
  font-weight: unset !important;
}
.pagenameabout {
  height: 140px;
  left: -205px;
  font-weight: 600;
  font-size: 130px;
  line-height: 140px;
  opacity: 50%;
  position: absolute;
  transform: rotate(-90deg);
  color: #ffffff;
  margin-top: 25%;
}
.temworkmake {
  color: white;
  font-weight: 400;
  font-size: 38px;
  line-height: 51px;
  font-family: Vampiro One;
}
.teamworkcontaint {
  border: 1px solid #f27221;
}
.dreamwork {
  color: #f27221;
}
.lastaboutsection {
  padding: 60px 20px 60px 50px;
}
.headerimgcontant {
  position: absolute;
  top: 55%;
  left: 13%;
  /* transform: translate(-40%, -50%); */
  /* font-size: 14px; */
  width: 70%;
}
.containtheaderline {
  color: #f27221;
  width: 20%;
  font-weight: 600;
}
.imgceo {
  box-shadow: #f27221 -15px 10px;
}
.imgcofounder {
  box-shadow: #f27221 -15px 0px;
  margin-left: 70px;
}

.ceo2detail {
  width: 129%;
}
.ceo2img {
  box-shadow: #f27221 -8px -7px 0px 0px;
  margin-left: 60px;
}
.marketingteamimg {
  width: 70%;
  border-radius: 22px;
}

.founderdetails
{
  width: 110%;
}
.innercontent2
{
  margin-left: 55px;
}

/* Mobile devices: */
@media only screen and (max-width: 480px) {
    .headerimgcontant {
        top: 20%;
        left: 13%;
        /* transform: translate(-40%, -50%); */
        font-size: 8px;
        width: 77%;
      }
      p{
        font-size: 16px;
      }

      .temworkmake {
        color: white;
        font-weight: 400;
        font-size: 25px;
        line-height: 51px;
        font-family: Vampiro One;
      }
      .ceo2img
      {
        width: 100%;
      }
      
      .imgcofounder {
        box-shadow: #f27221 -15px 0px;
        margin-left: auto;
      }
      .ceo2img {
        margin-left: auto;
      }
}

/* Tablets: */
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .headerimgcontant {
    top: 26%;
    left: 13%;
    transform: translate(-40%, -50%);
    font-size: 8px;
    width: 77%;
      }

      .imgcofounder {
        box-shadow: #f27221 -15px 0px;
        margin-left: auto;
      }

      .ceo2img {
        margin-left: auto;
      }
}

/* Desktops and laptops: */
@media only screen and (min-width: 769px) {
    .headerimgcontant {
        position: absolute;
        top: 100%;
        left: 13%;
        width: 70%;
      }
}

/* Large screens: */
@media only screen and (min-width: 1200px) {
}

.aboutnames
{
  color: #f27221;
  font-size: 30px;

}
.sechead2
{
  font-size: 24px !important;
}