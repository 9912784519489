@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline-offset: 4px;
}

button,
input {
  font: inherit;
}

.page {
  color: white;
  background-repeat: no-repeat;
  /* background-size: inherit; */
  background-position: center;
  background-image:linear-gradient(89.77deg, rgba(0, 0, 0, 0.91) 44.25%, rgba(0, 0, 0, 0.91) 108.28%), url('../images/headerlogo.webp');
  display: grid;
  background-color: black;
  grid-template-areas: "main";
  padding: var(--size-4);
  min-height: 100vh;
  background-size: 44% 34%;
  
}

@media only screen and (max-width: 360px) 
{
  .page {
    color: white;
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: center;
    background-image:linear-gradient(89.77deg, rgba(0, 0, 0, 0.56) 44.25%, rgba(0, 0, 0, 0.56) 108.28%), url('../images/headerlogo.webp');
    display: grid;
    background-color: black;
    grid-template-areas: "main";
    padding: var(--size-4);
    min-height: 100vh;
    font-family: var(--font-sans);
    background-size: 80% 65%;
  }

}

.page__main {
  grid-area: main;
}

.main {
  display: grid;
  align-items: center;
}
.main__login-form {
  margin-inline: auto;
  max-width: 25em;
}

.login-form {
  color: #433532;
  display: grid;
  position: relative;
  width: 100%;
  padding: var(--size-8);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 1em;
}
.login-form::before {
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  inset: 0;
  border-radius: inherit;
  content: "";
  z-index: -4000;
  box-shadow: 0 0 2em rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}
.login-form__title {
  margin-bottom: var(--size-6);
  font-weight: var(--font-weight-6);
  font-size: var(--font-size-5);
  text-align: center;
}
.login-form__label {
  margin-bottom: var(--size-4);
  display: grid;
}
.login-form__input {
  color: inherit;
  width: 100%;
  padding: 0.8em;
  border: 0;
  border-radius: var(--radius-2);
}
.login-form__footer {
  margin-top: var(--size-5);
  display: flex;
  gap: var(--size-2);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 36em) {
  .login-form__footer {
    flex-direction: row;
  }
}
.login-form__link {
  color: inherit;
  text-decoration: 0;
}
.login-form__link:hover {
  text-decoration: underline;
}

.primary-btn {
  color: white;
  background-color: #433532;
  padding: 0.9em 1.4em;
  border: 0;
  border-radius: var(--radius-2);
  cursor: pointer;
}
.primary-btn:hover {
  background-color: #c53b0d;
}

.sr-only {
  position: absolute;
  margin: -1px;
  width: 1px;
  height: 1px;
  padding: 0;
  border-width: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
}
.centered
{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ff450a;
    /* font-size: 224px; */
    font-weight: bolder;
    opacity: 0.3;
    font-family: Montserrat;
}

.sound-wave{
  position: relative;
}
.errormsg
{
  position: absolute;
}
.changenumber
{
  position: absolute;
  padding: 10px 30px;
  border-radius: 5px;
  background: hsla(201, 100%, 50%, 0.1);
  border: 1px solid #F27221;
  outline: none;
  color: #fff;
  font-weight: lighter;
  margin-left: 80%;
  
}
.changnumdiv
{
  margin-top: 20px;
  margin-bottom: 38px;
}

