@font-face {
  src: url("../public/assets/font/harvestitalregular.ttf");
  font-family: "HarvestItal";
}

@font-face {
  font-family: "Suissnord";
  src: url("../public/assets/font/Suissnord.otf");
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");

/* header button */
.headerbutton {
  position: relative;
  height: 65px;
  margin: 0 40px;
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 5px;
  text-transform: uppercase;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  background: #0d0d0d;
  overflow: hidden;
  transition: 0.6s;
  color: #fff;
  border-color: #ce5c0c;
  background-color: Transparent;
  /* padding: 20px; */
  white-space: nowrap;
}


.headerbutton:before,
.headerbutton:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  filter: blur(30px);
  opacity: 0.4;
  transition: 0.6s;
}

.headerbutton:before {
  width: 60px;
  background: rgba(255, 255, 255, 0.6);
  transform: translateX(-130px) skewX(-45deg);
}

.headerbutton:after {
  width: 30px;
  background: rgba(255, 255, 255, 0.6);
  transform: translateX(-130px) skewX(-45deg);
}

.headerbutton:hover:before,
.headerbutton:hover:after {
  opacity: 0.6;
  transform: translateX(320px) skewX(-45deg);
}

.headerbutton:hover {
  color: #f2f2f2;
}

.headerbutton:hover {
  background: #ce5c0c;
}

.firstletter {
  color: #f27221;
}

.firstletter2 {
  text-decoration: overline;
  color: #f27221;
  padding: 5px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 40%;
  /* left: 50%; */
  transform: translate(-50%, -50%);
  color: #ff450a;
  font-size: 600px;
  font-weight: bolder;
  opacity: 0.1;
  font-family: Montserrat;
  width: 80%;
  height: 100%;
}

.centeredtop {
  position: absolute;
  top: 13%;
  left: 30%;
  transform: translate(-50%, -50%);
  color: #fff;
  /* font-weight: bolder; */
  font-family: "HarvestItal";
  font-size: 60px;
}

.centeredtop2 {
  position: absolute;
  top: 21%;
  left: 40%;
  transform: translate(-50%, -50%);
  color: #f27221;
  /* font-weight: bolder; */
  font-family: "HarvestItal";
  font-size: 60px;
}

.sportx {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bolder;
  font-size: 120px;
  font-family: "Suissnord";
  width: 100%;
}

.sportx2 {
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f27221;
  font-weight: bolder;
  font-family: "Suissnord";
  font-size: 120px;
  max-width: 100%;
}

/* .img-fluid
  {
    
  } */
/* End */
#team {
  background-color: black !important;
}

#services {
  background-color: black !important;
}

#about {
  background-color: black !important;
}

.contactheding {
  color: black !important;
}

#testimonials {
  color: black !important;
}

.serviceimg {
  height: 90px;
}

.imgfooterlogo {
  max-width: 30%;
}

.fitnessdetailnuber {
  color: #f27221;
  font-size: 100px;
  /* margin-left: 25px; */
  text-decoration: underline;
}

.contentsmallimages {
  width: 100%;
  height: 100%;
}

.contentsmallimages2 {
  width: 100%;
  height: 300px;
}

.borderline {
  color: #f27221;
  margin-top: 20px;

  border: 2px solid;
  width: 100%;
}

/* faq css */
/* structure */
.faq {
  background: #000000;
  padding: unset !important;
}

.d-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  height: 90vh;
  /* padding: 2rem; */
}

footer {
  height: 10vh;
}

details[open],
details summary,
.d-imgs {
  display: block;
  width: 500px !important;
}

details summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
  cursor: pointer;
  font-weight: 400;
  font-size: 1.5rem;
  width: 1200px !important;
}

details summary:after {
  content: "›";
  font-size: 2rem;
  transition: 0.3s;
  transform: rotate(90deg);
}

/* transitions */
details {
  margin-bottom: 1rem;
  height: 4rem;
  transition: height 0.3s ease;
}

details[open] {
  height: 10rem;
}

details p {
  height: fit-content;
  padding: 1rem;
  width: 1200px !important;
}

details[open] summary:after {
  transform: rotate(-90deg);
}

/* Imgs */
.imgs {
  display: block;
  height: 100%;
  width: 100%;
  background-size: contain;
  border-radius: 2rem;
}

/* responsive */
@media (max-width: 1190px) {

  body,
  .d-display {
    height: fit-content;
    width: 100%;
  }

  .d-display {
    justify-content: center;
    padding: 2rem 0;
  }
}

/* faq end */

/* testimonial start */

figure.snip1390 {
  font-family: "Roboto", Arial, sans-serif;
  position: relative;
  overflow: hidden;
  /* margin: 10px; */
  /* margin-left: 48px; */
  /* min-width: 80%; */
  /* max-width: 80%; */
  width: 100%;
  color: #000000;
  text-align: center;
  font-size: 16px;
  background-color: #2c3447;
  padding: 30px;
  background-image: linear-gradient(-25deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(255, 255, 255, 0.1) 100%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

figure.snip1390 *,
figure.snip1390 *:before,
figure.snip1390 *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

figure.snip1390 figcaption {
  width: 100%;
}

figure.snip1390 h2,
figure.snip1390 h4,
figure.snip1390 blockquote {
  margin: 10%;
}

figure.snip1390 h2,
figure.snip1390 h4 {
  font-weight: 300;
}

figure.snip1390 h2 {
  color: #ffffff;
}

figure.snip1390 h4 {
  color: #a6a6a6;
}

figure.snip1390 blockquote {
  font-size: 1em;
  /* padding: 45px 20px 40px 50px; */
  margin-top: 30px;
  color: white;
  /* background-color: #ffffff; */
  border-radius: 5px;
  /* box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3); */
  text-align: center;
  position: relative;
  height: 76px;
}

/* figure.snip1390 blockquote:before {
    font-family: 'FontAwesome';
    content: "\201C";
    position: absolute;
    font-size: 70px;
    opacity: 0.25;
    font-style: normal;
    top: 0px;
    left: 20px;
  } */
figure.snip1390 .profile {
  width: 100px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
  /* border: solid 5px #A6A57A; */
}

/* testimonial end */

/* star css */

.rate {
  /* float: left; */
  height: 46px;
  padding: 0 10px;
  margin-top: 30px;
}

.rate:not(:checked)>input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked)>label {
  /* float:right; */
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}

.rate:not(:checked)>label:before {
  content: "★ ";
}

.rate>input:checked~label {
  color: #ffc700;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
  color: #deb217;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
  color: #c59b08;
}

/* Slider buttons */

/* .carousel-indicators {
  position: absolute;
  right: auto;
  bottom: 180px;
  left: 0;
  z-index: 2;
  display: inline-grid;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 5%;
} */
.number {
  font-weight: bolder;
  font-size: 20px;
  cursor: pointer;
}

.active .number {
  opacity: 1;
  color: #f27221 !important;
}

.sliderbtn {
  margin: 10px;
  display: inline-flex;
}

/* .carousel-indicators [data-bs-target] {
  color: white !important;
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 28px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: unset;
  cursor: pointer;
  background-color: transparent;
  background-clip: padding-box;
  border: 0;
  border-top-width: 0px;
  border-top-style: none;
  border-top-color: currentcolor;
  border-bottom-width: 0px;
  border-bottom-style: none;
  border-bottom-color: currentcolor;
  border-top: 10px solid transparent;
  border-bottom: unset !important;
  opacity: unset !important;
  transition: opacity 0.6s ease;
} */
.sliderbtabtext {
  margin-left: 20px;

  padding: 3px 66px;
  background-color: #f27221;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  /* display: ruby; */
}

.sliderbtn .sliderbtabtext {
  display: none;
}

.sliderbtn.active .sliderbtabtext {
  display: block;
  white-space: nowrap;
}

/* model form css */
.modal {
  --bs-modal-bg: rgba(59, 58, 58, 0.7);
}

.come-from-modal.left .modal-dialog,
.come-from-modal.right .modal-dialog {
  /* position: fixed; */
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  float: right;
  /* margin-right: -15px; */
}

.come-from-modal.left .modal-content,
.come-from-modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0px;
}

.come-from-modal.left .modal-body,
.come-from-modal.right .modal-body {
  padding: 15px 15px 80px;
}

.come-from-modal.right.fade .modal-dialog {
  /* right: 16px; */
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.come-from-modal.right.fade.in .modal-dialog {
  right: 0;
}

/* menu */
/*!
   * Start Bootstrap - Simple Sidebar HTML Template (http://startbootstrap.com)
   * Code licensed under the Apache License v2.0.
   * For details, see http://www.apache.org/licenses/LICENSE-2.0.
   */

/* Toggle Styles */

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  /* overflow-y: auto; */
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav>.sidebar-brand {
  height: 50px;
  font-size: 18px;
  line-height: 60px;
  text-align: right;
}

.sidebar-nav>.sidebar-brand a {
  color: white;
}

.sidebar-nav>.sidebar-brand a:hover {
  color: #fff;
  background: none;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    padding: 20px;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

.menudevider {
  margin-left: 80px;
  border: 1px solid white;
}

.close-btn {
  margin-left: 220px;
  background: none !important;
  color: white;
  border: 1px solid;
  font-size: 28px;
  width: 52px;
  border-radius: 5px;
}

.sec-head {
  color: white;
  font-size: 40px;
}

p {
  color: white;
}

.contactheader {
  color: #000000 !important;
}

.carousel-control-prev {
  display: none;
}

.carousel-control-next {
  display: none;
}

/* slider  */
h2 {
  text-align: center;
  padding: 20px;
}

/* Slider */

/* slider end */
.testimonialcss {
  background: #000000 !important;
}

.slide {
  text-align: center;
}

.testimonilaname {
  font-family: Montserrat;
  white-space: nowrap;
}

.memberimg {
  /* height: 100% !important; */
  border-radius: 26px;
  overflow: hidden;
}

@media only screen and (max-width: 480px) {
  .aboutcontenar {
    margin-left: 17px;
  }


}

.dnatestbtn {
  color: #ffffff;
  /* font-size: 13px; */
  width: 60%;
  /* font-weight: bolder; */
  position: relative;
  background: black;
  margin-bottom: 10px;

  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  /* text-align: left; */
  border: 1px #f27221 solid;
  box-shadow: rgb(169, 169, 169) 1px 0px 15px;
}

.dnsheader {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: bolder;
  font-family: Montserrat;
  font-style: italic;
  font-size: 77px !important;
  text-shadow: 2px 2px 10px rgba(255, 202, 99, 2);
}

.dnsbtns {
  height: 65px;
  margin: 10px 1px;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 50px;
  border: 1px solid transparent;
  outline: none;
  background: black;
  transition: 0.6s;
  border-color: #ce5c0c;
  width: 100%;
}

.dnaheadming {
  margin-left: 50px !important;
  font-family: Montserrat;
  font-style: italic;
  font-size: 40px;
}

.dnadetails {
  /* margin-left: 52px; */
  font-size: 25px;
}

.dnsicobtn {
  border-radius: 20px;
  border: 1px solid transparent;
  outline: none;
  /* background: black; */
  transition: 0.6s;
  border-color: #ce5c0c;
}

.iconsize {
  color: white;
  font-size: 160px;
  /* margin: 46px; */
  padding: 20px;
}

.icodetail {
  margin: 30px 113px;
  color: #f27221;
}

.icocontent {
  font-size: 26px;
}

.healthdataheading {
  font-family: Montserrat;
  font-size: 50px;
  font-style: italic;
  font-weight: 800;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
}

/* video css */

.home {
  height: 100vh;
  position: relative;
  background-color: black;
}

video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
}

.home-content {
  position: relative;
  padding-top: 150px;
  color: #fff;
  text-align: center;
}

/* h1 {
  font-family: Playfair Display, serif;
  font-size: clamp(45px, 7vw, 130px);
  line-height: 1.1;
} */

.home p {
  font-size: clamp(25px, 4vw, 40px);
  margin-top: 10px;
}

.home-content button {
  display: block;
  font-size: clamp(14px, 1.5vw, 18px);
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  background: transparent;
  color: #fff;
  margin: 50px auto 0;
  padding: 12px 20px;
  cursor: pointer;
}

.toggolemenu {
  height: 14px;
  width: 29px;
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  #header {
    background: rgba(0, 0, 0, 0.75);
    transition: all 0.5s;
    z-index: 997;
    height: 50px;
  }

  #header .logo img {
    max-height: 54px;
  }

  .centered {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ff450a;
    font-size: 224px;
    font-weight: bolder;
    opacity: 0.1;
    font-family: Montserrat;
  }

  .sportx {
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-size: 32px;
    font-family: "Suissnord";
  }

  .sportx2 {
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-family: "Suissnord";
    font-size: 32px;
  }

  /* .carousel-indicators {
    position: absolute;
    right: auto;
    bottom: 0px;
    left: 0;
    z-index: 2;
    display: inline-grid;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 8%;
  } */
  .number {
    font-weight: bolder;
    font-size: 14px;
  }

  /* .carousel-indicators [data-bs-target] {
    color: white !important;
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 2px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: unset;
    cursor: pointer;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor;
    border-bottom-width: 0px;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
    border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor;
    border-bottom-width: 0px;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
    border-top: 10px solid transparent;
    border-bottom: unset !important;
    opacity: unset !important;
    transition: opacity 0.6s ease;
  } */
  #hero .carousel-item {
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    transition: 0.3;
  }

  .sliderbtabtext {
    margin-left: 17px;
    padding: 6px 24px;
    background-color: #f27221;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
  }

  .ourcoacheimg {
    width: 50%;
  }

  .section-title h2 {
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: white;
  }

  section {
    padding: 20px;
  }

  .sliderdetails {
    text-align: center;
    font-size: 5px;
    margin: 0 auto -9px auto !important;
  }

  .sliderbtn {
    margin: 7px;
  }
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: unset !important;
  margin-left: 15%;
}

@media only screen and (min-width: 480px) and (max-width: 480px) {
  #header {
    background: rgba(0, 0, 0, 0.75);
    transition: all 0.5s;
    z-index: 997;
    height: 50px;
  }

  #header .logo img {
    max-height: 54px;
  }

  .centered {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ff450a;
    font-size: 292px;
    font-weight: bolder;
    opacity: 0.3;
    font-family: Montserrat;
  }

  .sportx {
    position: absolute;
    top: 58%;
    left: 39%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-size: 26px;
    font-family: "Suissnord";
  }

  .sportx2 {
    position: absolute;
    top: 58%;
    left: 56%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-family: "Suissnord";
    font-size: 27px;
  }

  /* .carousel-indicators {
    position: absolute;
    right: auto;
    bottom: 82px;
    left: 0;
    z-index: 2;
    display: inline-grid;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 8%;
  } */
  /* .number {
    font-weight: bolder;
    font-size: 14px;
  } */
  /* .carousel-indicators [data-bs-target] {
    color: white !important;
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 2px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: unset;
    cursor: pointer;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor;
    border-bottom-width: 0px;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
    border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor;
    border-bottom-width: 0px;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
    border-top: 10px solid transparent;
    border-bottom: unset !important;
    opacity: unset !important;
    transition: opacity 0.6s ease;
  } */
  #hero .carousel-item {
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    transition: 0.3;
  }

  .sliderbtabtext {
    margin-left: 17px;
    padding: 6px 24px;
    background-color: #f27221;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
  }

  #header {
    background: rgba(0, 0, 0, 0.75);
    transition: all 0.5s;
    z-index: 997;
    height: 55px;
  }

  /* .sliderdetails {
    width: unset !important;
    margin: 14px -59px -24px 5px !important;
    font-size: 10px !important;
  } */

  .ourcoacheimg {
    width: 50%;
  }

  .section-title h2 {
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: white;
  }

  section {
    padding: 20px;
  }

  .sliderdetails {
    text-align: center;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {

  #header {
    background: rgba(0, 0, 0, 0.75);
    transition: all 0.5s;
    z-index: 997;
    height: 50px;
  }

  #header .logo img {
    max-height: 54px;
  }

  .centered {
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ff450a;
    font-size: 335px;
    font-weight: bolder;
    opacity: 0.1;
    font-family: Montserrat;
  }

  .sportx {
    position: absolute;
    top: 38%;
    left: 54%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-size: 40px;
    font-family: "Suissnord";

  }

  .sportx2 {
    position: absolute;
    top: 50%;
    left: 56%;
    transform: translate(-50%, -50%);
    color: #f27221;
    font-weight: bolder;
    font-family: "Suissnord";
    font-size: 40px;
  }



  .number {
    font-weight: bolder;
    font-size: 14px;
  }

  /* .carousel-indicators [data-bs-target] {
    color: white !important;
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 2px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: unset;
    cursor: pointer;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor;
    border-bottom-width: 0px;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
    border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor;
    border-bottom-width: 0px;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
    border-top: 10px solid transparent;
    border-bottom: unset !important;
    opacity: unset !important;
    transition: opacity 0.6s ease;
  } */
  #hero .carousel-item {
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    transition: 0.3;
  }

  .sliderbtabtext {
    margin-left: 17px;
    padding: 6px 24px;
    background-color: #f27221;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
  }

  #header {
    background: rgba(0, 0, 0, 0.75);
    transition: all 0.5s;
    z-index: 997;
    height: 55px;
  }

  .ourcoacheimg {
    width: 50%;
  }

  /* .sliderdetails {
    width: unset !important;
    margin: 14px -59px -24px 5px !important;
    font-size: 10px !important;
  } */

  .section-title h2 {
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: white;
  }

  section {
    padding: 20px;
  }

  .sliderdetails {
    text-align: center;
    font-size: 8px;
    margin: 0 auto 30px auto !important;
  }
}

.slide1 {
  display: block;
}

.slide2 {
  display: none;
}

.slide3 {
  display: none;
}

.slide4 {
  display: none;
}

.slide5 {
  display: none;
}

.slide6 {
  display: none;
}

.reponsive1,
.reponsive2,
.reponsive3,
.reponsive4,
.reponsive5,
.reponsive6,
.reponsive7,
.reponsive8 {
  display: none;
}

.readmorebtn {
  color: #ffffff;
  font-size: 12px;
  width: 43%;
  position: relative;
  background: black;
  margin-bottom: 10px;
  font-family: Poppins;
  font-size: 20px;
  text-align: center;
  border: 1px #f27221 solid;
  margin-left: 14px;
  /* transition: all ease 0.10s ; */
}

.sliderbtabtext {
  display: none !important;
}

li.list1 {
  list-style-image: radial-gradient(45deg, red, red 50%, orange 50%, orange);
  padding: unset !important;
}

.readmorebtns {
  color: #f8f9fa;
}

.readmorebtns:hover {
  color: #f8f9fa;
}

.testimonial {
  height: 100%;
}

.testimonialheader {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: white;
}

.contactusHeading1 {
  white-space: nowrap;
}

/* .contactusHeading2
{
 
} */

.contactuspage {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  color: #f27221;
}

.customeinput {
  /* font-size: 1.5em; */
  /* padding: 0.1em 0.25em; */
  background-color: transparent;
  /* border: 1px solid hsl(0, 0%, 100%); */
  border: 1px solid hsla(0, 0%, 6.7%, 0.36);
  outline: none;
  font-weight: lighter;
  /* opacity: 0.6 !important; */
  border-radius: unset;
  /* color: white !important; */
  color: #000000;
  font-weight: 200 !important;
}

.customeinput::placeholder {
  /* color: white !important; */
  color: #000000;
  font-weight: 200;
}

.customeinput:focus {
  color: var(--bs-body-color);
  background-color: transparent;
  /* border-color: #f4f6f8; */
  border-color: #000000;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgb(255, 255, 255);
}

.submitcontactus {
  background: #FF450A;
  border: 0;
  padding: 10px 30px 12px 30px;
  color: #fff;
  font-weight: bold;
  transition: 0.4s;
  border-radius: 50px;
  width: 70%;
  font-size: 1vw;
}

.modelsubmitbtn
{
  margin-top: -20px;
}
.contacuspagedetails {
  font-weight: bold;
}

.footerpointes {
  margin-left: 12px;
}

/* .icon-input {
  padding-right: 25px;
  background: url("") no-repeat right;
  background-size: 20px;
} */


.iconcontent {
  color: #F27221;
  font-size: 42px;
  -webkit-text-stroke-width: 1.7px;
  margin-top: auto;
}

.mrleaft1 {
  margin-top: 12%;
}

.mrleaft2 {
  margin-top: 10%;
}


.bgimgcontent {
  height: 50%;
  width: 95%;
}

.submitbtn {
  background: #FF450A;
  border: 0;
  padding: 10px 30px 12px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 80px;
  width: 80%;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1437px !important;
}

.servicesheading {
  color: white;
}

.testimonialvideo {
  position: unset !important;
  opacity: unset;
}

@media (min-width: 360px) {

  .headerbutton {
    position: relative;
    width: 150px;
    height: 21px;
    margin: 0 40px;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 2px;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    outline: none;
    cursor: pointer;
    background: #0d0d0d;
    background-color: rgb(13, 13, 13);
    overflow: hidden;
    transition: 0.6s;
    color: #fff;
    border-color: #ce5c0c;
    background-color: Transparent;
  }
}

@media (min-width: 480px) {

  .headerbutton {
    position: relative;
    width: 150px;
    height: 21px;
    margin: 0 40px;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 2px;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    outline: none;
    cursor: pointer;
    background: #0d0d0d;
    background-color: rgb(13, 13, 13);
    overflow: hidden;
    transition: 0.6s;
    color: #fff;
    border-color: #ce5c0c;
    background-color: Transparent;
  }
}

@media (min-width: 576px) {

  .headerbutton {
    position: relative;
    height: 65px;
    width: 210px;
    margin: 0 40px;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 2px;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    outline: none;
    cursor: pointer;
    background: #0d0d0d;
    background-color: rgb(13, 13, 13);
    overflow: hidden;
    transition: 0.6s;
    color: #fff;
    border-color: #ce5c0c;
    background-color: Transparent;
  }
}

@media (min-width: 920px) {

  .headerbutton {
    position: relative;
    height: 65px;
    width: 28%;
    margin: 0 40px;
    font-size: 23px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 7px;
    text-transform: uppercase;
    border: 2px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    outline: none;
    cursor: pointer;
    background: #0d0d0d;
    background-color: rgb(13, 13, 13);
    overflow: hidden;
    transition: 0.6s;
    color: #fff;
    border-color: #ce5c0c;
    background-color: Transparent;
  }
}

.strandconditon {
  margin-top: auto;
  margin-left: 5%;
  font-size: 40px;
}

.fatloss {
  margin-top: 36px;
  margin-left: 5%;
  font-size: 40px;
}

.directionicon {
  height: 100%;
  /* width: 100%; */
}

.sporttringicon {
  margin-top: 18%;
}

.strengthicon {
  margin-top: 8%;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 7px;
  height: 7px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
  border-radius: 50%;
}

.carousel-indicators .active {
  opacity: 1;
  width: 10px;
  height: 10px;
  display: flex;
}

@media (min-width: 360px) {
  #hero p {
    width: 100%;
  }

  .strandconditon {
    margin-top: auto;
    margin-left: 5%;
    font-size: 30px;
    padding-bottom: 18px;
  }

  .fatloss {
    margin-top: 36px;
    margin-left: 5%;
    font-size: 30px;
    padding-bottom: 18px;
  }

}

@media (min-width: 480px) {
  #hero p {
    width: 100%;
  }
}

@media (min-width: 570px) {
  #hero p {
    width: 100%;
  }
}

@media (min-width: 920px) {
  #hero p {
    width: 100%;
  }
}

.coachimgrow {
  justify-content: center;
}

.foundreandcoche {
  color: #f27221;
  font-size: 50px;
}

.closebtn
{
  justify-content: end;
}
.closebtnpopup
{
  position: relative;
  z-index: 99;
  top: 80px;
  background: black  !important;
}

.mdalog {
  pointer-events: unset !important; 
}
/* Model form css */


@media (min-width: 570px) {
  .mobileview
  {
    display: none !important;
  }

  }

/* mobile popup css */
.pickgradient{
  position:relative;
  display:inline-block;
}
.pickgradient:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.65)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

.imgmobilepopup{
  position:relative;
  z-index:-1;
  display:block;
  /* height:200px; width:auto; */
  width: 100%;
  height: 600px;
}
.punes
{
  color: #f27221;
}
.header
{
  position: absolute;
  padding-top: 75px;
}
.letstart
{
  color: white;
}
.modelformresponsive
{
  position: absolute;
  font-size: 3vw;
  padding-top: 150px;
  background: #fff;
}
.responsivelogomodel
{
  height: 82%;
  width: 70px;
  position: absolute;
  z-index: 99;
  top: 94px;
  left: 13px;
}

.header p{
  font-size: 2vw;
}
.modelmsg
{
   color: #000000;
   font-size: 55%;
}

/* whatapp */
.btn-whatsapp-pulse {
	background: #25d366;
	color: white;
	position: fixed;
	bottom: 20px;
	right: 20px;
	font-size: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0;
	height: 0;
	padding: 28px;
	text-decoration: none;
	border-radius: 50%;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}

.btn-whatsapp-pulse-border {
	bottom: 120px;
	right: 20px;
	animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
	content: "";
	position: absolute;
	border-radius: 50%;
	padding: 25px;
	border: 5px solid #25d366;
	opacity: 0.75;
	animation-name: pulse-border;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse-border {
	0% {
		padding: 25px;
		opacity: 0.75;
	}
	75% {
		padding: 50px;
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}



/* slider */
/* CSS for smooth transitions */
.carousel-item {
  transition: opacity 1s ease-in-out;
}

/* CSS for active carousel item */
.carousel-item.active {
  opacity: 1;
}

@media (max-width: 750px) 
{
  .header p {
    font-size: 7vw;
  }
  .modelsubmitbtn {
    margin-top: 0px;
  }
  .submitcontactus
  {
    font-size: 4vw;
  }
 .desktopviewpopup {
  display: none !important;;
 }

 .modelmsg
{
   color: white;
  font-size: 5vw
}

}
.btn-whatsapp-pulse
{
  color: white !important;
}
.main-form::placeholder{
  color: white !important;
}
.main-form{
  border: 1px solid hsl(0, 0%, 100%);
  color: #fff !important;
}