.categories {
    border-radius: 155px !important;
    height: 100px !important;
    width: 100px !important;
}

.swiperimg {
    border-radius: 200px !important;
    height: 200px;
    width: 200px;

}
.swiperimg2 {
    border-radius: 15px !important;
    height: 200px;
    width: 90%;

}
.swiper-container {
    text-align: center;
}

.detail_name {
    color: white;
    font-size: medium;
}

.strenghtimg {
    border-radius: 20px;
    width: 70%;
    height: 84%;
}

.readmore {
    color: #F27221;
}
.readmore_arr
{
    height: 12px;
}
.containtDetail
{
    color: white;
}
.blogdesc
{
    padding: 40px;
}
.blogdesc p
{
   background:unset !important ;
   color: white !important;
}
.blogdesc span
{
   background:unset !important ;
   color: white !important;
}
.strengthname
{
    color: white;
}
.similartag
{
    color: white;
}

@media only screen and (min-width: 360px) and (max-width: 570px) {
    .blogdesc img
    {
      width: 100% !important;
    } 
}

/* @media (min-width: 480px) {
    .blogdesc img
    {
      width: 100% !important;
    } 
} */

.reletedblog
{
    padding: 40px;
}
/* .owl-item .center
{
   opacity: 0.1;
}
.owl-item:active .center
{
    opacity: 0.5;
} */

.blogcard
{
    background-color:unset !important
}
.owl-carousel .owl-item .blogcard {
    padding: 0px !important;
}