
    @media only screen and (max-width: 480px) { 
        .servicesrevert
        {
          display: flex;
          flex-direction: column-reverse;
        }
     }
 

    @media only screen and (min-width: 481px) and (max-width: 768px) { 
        .servicesrevert
        {
          display: flex;
          flex-direction: column-reverse;
        }
     }
